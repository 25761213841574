## Public services

- [Mastodon instance mstdn.soine.site](https://mstdn.soine.site)
- soine.site
    - [soine.site](https://soine.site)
    - [soine.site Wiki](https://wiki.soine.site)
- Minecraft Multiplayer
    - Server address: `yude.jp`
- TeamSpeak 3 server
    - Server address: `yude.jp`
- Internet Relay Chat
    - Server address: `irc.yude.jp`
- OpenTTD Multiplayer
    - Server address: `openttd.yude.jp`

## Links

- [GitHub Organization](https://github.com/yudejp)
- [Discord server](https://github.com/yudejp)
- [Service status, Failure history](https://status.yude.jp)

## Connectivity

- The Onion Router
    - `yudejpwxp2cziclocqjfd55ucw2dh6ncswopluh7exwusjlfkvkwhwqd.onion`
- I2P
    - `idabfrazqbh7upvo2f5hx3ajpqglrwny66qbvcoatfqoq64ifiaq.b32.i2p`
- Gemini
    - `gemini://gemini.yude.jp`
