export default function Logo() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="140px"
        viewBox="0 0 188 127"
        enableBackground="new 0 0 188 127"
        xmlSpace="preserve"
      >
        <path
          fill="transparent"
          opacity="1.000000"
          stroke="none"
          d="
          M104.000000,128.000000 
              C69.333336,128.000000 35.166676,128.000000 1.000009,128.000000 
              C1.000006,85.666679 1.000006,43.333351 1.000003,1.000021 
              C63.666653,1.000014 126.333305,1.000014 188.999969,1.000007 
              C188.999969,43.333309 188.999969,85.666618 188.999985,127.999969 
              C160.833328,128.000000 132.666672,128.000000 104.000000,128.000000 
          M7.117876,115.084213 
              C5.889924,118.927208 4.661973,122.770210 3.374049,126.800903 
              C4.037380,126.518517 4.548574,126.410423 4.925265,126.124886 
              C29.367737,107.597252 45.773930,83.709450 53.146019,53.795887 
              C54.845459,46.900120 52.379135,44.010975 44.977520,44.300926 
              C42.596584,57.062340 28.429207,80.452438 19.764647,85.355591 
              C16.319317,70.818230 22.221556,58.589859 28.158113,46.356812 
              C23.821831,41.336983 20.668497,40.704533 17.729305,44.043751 
              C11.102881,51.572037 10.277803,61.563412 8.528805,70.412811 
              C6.232768,82.030052 12.745938,88.869507 25.522543,92.843063 
              C20.649284,98.552498 15.926814,104.018776 11.291307,109.557823 
              C9.949220,111.161507 8.856617,112.973976 7.117876,115.084213 
          M138.960434,54.922997 
              C139.281265,52.650780 139.426544,50.337063 139.952332,48.113300 
              C142.696655,36.506275 145.496796,24.911974 148.368439,13.335796 
              C149.300049,9.580265 148.062408,7.391997 143.593353,6.365087 
              C139.190659,19.892176 134.812714,33.343258 130.424881,46.824673 
              C128.995987,45.848377 127.491920,44.126350 125.709358,43.760201 
              C122.796143,43.161812 118.361977,42.110813 116.900993,43.575851 
              C107.479286,53.023705 100.637360,64.078209 100.022728,77.945351 
              C99.737427,84.381958 105.360855,91.011276 111.744209,91.790337 
              C113.142479,91.960991 114.898155,91.116356 116.146210,90.246902 
              C119.945129,87.600395 123.576363,84.713196 126.245026,82.696465 
              C128.098267,92.437515 128.098267,92.437515 131.615204,92.582428 
              C134.036850,80.208176 136.436798,67.944778 138.960434,54.922997 
          M96.027229,47.242535 
              C90.700607,48.315754 96.663048,42.452709 91.929405,42.587360 
              C88.794617,49.945488 85.582726,57.919407 82.017395,65.732025 
              C78.393860,73.672203 74.243607,81.312515 65.817375,87.011261 
              C62.849075,71.125656 69.677933,58.427010 74.705162,44.497776 
              C70.984833,44.479759 67.749718,38.798359 64.614944,43.914261 
              C57.824837,54.995590 54.578793,67.348145 54.593021,80.387016 
              C54.595127,82.314407 55.756741,84.706421 57.162666,86.090240 
              C65.826630,94.617996 76.726097,94.388458 83.148842,84.146385 
              C89.519341,73.987648 93.905708,62.579113 99.051323,51.666393 
              C99.568245,50.570107 99.576515,49.050957 99.293320,47.852737 
              C99.183846,47.389561 97.606201,47.273380 96.027229,47.242535 
          M158.760361,45.145054 
              C150.389236,51.742550 144.005066,59.798492 140.977814,70.147713 
              C140.009048,73.459663 139.458389,76.938805 139.122726,80.381073 
              C138.544922,86.306343 141.388718,88.901268 150.415176,91.183258 
              C155.884399,92.565933 160.599533,90.189713 162.177872,85.020363 
              C162.516876,83.910004 162.376724,82.200722 161.704468,81.397171 
              C161.273544,80.882072 159.300583,81.199081 158.302124,81.703636 
              C155.436157,83.151886 152.714371,84.885475 149.935928,86.506912 
              C149.343521,86.161224 148.751129,85.815544 148.158722,85.469856 
              C149.818878,78.617493 151.479050,71.765137 153.195801,64.679161 
              C155.145111,65.503990 155.893295,65.830124 156.648636,66.138756 
              C167.559937,70.597130 178.605759,64.010147 179.815750,52.215305 
              C179.954208,50.865692 179.622314,49.159218 178.858459,48.077095 
              C174.740677,42.243652 165.489288,40.782150 158.760361,45.145054 
          z"
        />
        <path
          className="color-fill"
          opacity="1.000000"
          stroke="none"
          d="
          M7.383759,114.887474 
              C8.856617,112.973976 9.949220,111.161507 11.291307,109.557823 
              C15.926814,104.018776 20.649284,98.552498 25.522543,92.843063 
              C12.745938,88.869507 6.232768,82.030052 8.528805,70.412811 
              C10.277803,61.563412 11.102881,51.572037 17.729305,44.043751 
              C20.668497,40.704533 23.821831,41.336983 28.158113,46.356812 
              C22.221556,58.589859 16.319317,70.818230 19.764647,85.355591 
              C28.429207,80.452438 42.596584,57.062340 44.977520,44.300926 
              C52.379135,44.010975 54.845459,46.900120 53.146019,53.795887 
              C45.773930,83.709450 29.367737,107.597252 4.925265,126.124886 
              C4.548574,126.410423 4.037380,126.518517 3.374049,126.800903 
              C4.661973,122.770210 5.889924,118.927208 7.383759,114.887474 
          z"
        />
        <path
          className="color-fill"
          opacity="1.000000"
          stroke="none"
          d="
          M138.898590,55.302189 
              C136.436798,67.944778 134.036850,80.208176 131.615204,92.582428 
              C128.098267,92.437515 128.098267,92.437515 126.245026,82.696465 
              C123.576363,84.713196 119.945129,87.600395 116.146210,90.246902 
              C114.898155,91.116356 113.142479,91.960991 111.744209,91.790337 
              C105.360855,91.011276 99.737427,84.381958 100.022728,77.945351 
              C100.637360,64.078209 107.479286,53.023705 116.900993,43.575851 
              C118.361977,42.110813 122.796143,43.161812 125.709358,43.760201 
              C127.491920,44.126350 128.995987,45.848377 130.424881,46.824673 
              C134.812714,33.343258 139.190659,19.892176 143.593353,6.365087 
              C148.062408,7.391997 149.300049,9.580265 148.368439,13.335796 
              C145.496796,24.911974 142.696655,36.506275 139.952332,48.113300 
              C139.426544,50.337063 139.281265,52.650780 138.898590,55.302189 
          M122.285957,68.765839 
              C126.118958,63.505871 130.830658,58.629768 129.960693,51.310402 
              C125.618050,49.904881 122.168259,50.297810 119.640411,54.460430 
              C115.311752,61.588478 111.731674,69.204430 108.303001,76.825050 
              C107.296989,79.061012 107.865273,82.005280 107.712036,84.624924 
              C108.360924,84.869331 109.009804,85.113747 109.658691,85.358154 
              C113.755936,80.039864 117.853172,74.721573 122.285957,68.765839 
          z"
        />
        <path
          className="color-fill"
          opacity="1.000000"
          stroke="none"
          d="
          M96.362335,47.120827 
              C97.606201,47.273380 99.183846,47.389561 99.293320,47.852737 
              C99.576515,49.050957 99.568245,50.570107 99.051323,51.666393 
              C93.905708,62.579113 89.519341,73.987648 83.148842,84.146385 
              C76.726097,94.388458 65.826630,94.617996 57.162666,86.090240 
              C55.756741,84.706421 54.595127,82.314407 54.593021,80.387016 
              C54.578793,67.348145 57.824837,54.995590 64.614944,43.914261 
              C67.749718,38.798359 70.984833,44.479759 74.705162,44.497776 
              C69.677933,58.427010 62.849075,71.125656 65.817375,87.011261 
              C74.243607,81.312515 78.393860,73.672203 82.017395,65.732025 
              C85.582726,57.919407 88.794617,49.945488 91.929405,42.587360 
              C96.663048,42.452709 90.700607,48.315754 96.362335,47.120827 
          z"
        />
        <path
          className="color-fill"
          opacity="1.000000"
          stroke="none"
          d="
          M159.092346,44.983562 
              C165.489288,40.782150 174.740677,42.243652 178.858459,48.077095 
              C179.622314,49.159218 179.954208,50.865692 179.815750,52.215305 
              C178.605759,64.010147 167.559937,70.597130 156.648636,66.138756 
              C155.893295,65.830124 155.145111,65.503990 153.195801,64.679161 
              C151.479050,71.765137 149.818878,78.617493 148.158722,85.469856 
              C148.751129,85.815544 149.343521,86.161224 149.935928,86.506912 
              C152.714371,84.885475 155.436157,83.151886 158.302124,81.703636 
              C159.300583,81.199081 161.273544,80.882072 161.704468,81.397171 
              C162.376724,82.200722 162.516876,83.910004 162.177872,85.020363 
              C160.599533,90.189713 155.884399,92.565933 150.415176,91.183258 
              C141.388718,88.901268 138.544922,86.306343 139.122726,80.381073 
              C139.458389,76.938805 140.009048,73.459663 140.977814,70.147713 
              C144.005066,59.798492 150.389236,51.742550 159.092346,44.983562 
          M156.939560,59.873901 
              C156.515167,60.803970 156.090759,61.734035 155.582108,62.848743 
              C162.434006,62.493137 167.430557,57.181343 167.946609,48.634769 
              C164.426483,52.170654 160.906357,55.706539 156.939560,59.873901 
          z"
        />
        <path
          fill="transparent"
          opacity="1.000000"
          stroke="none"
          d="
          M122.118187,69.084564 
              C117.853172,74.721573 113.755936,80.039864 109.658691,85.358154 
              C109.009804,85.113747 108.360924,84.869331 107.712036,84.624924 
              C107.865273,82.005280 107.296989,79.061012 108.303001,76.825050 
              C111.731674,69.204430 115.311752,61.588478 119.640411,54.460430 
              C122.168259,50.297810 125.618050,49.904881 129.960693,51.310402 
              C130.830658,58.629768 126.118958,63.505871 122.118187,69.084564 
          z"
        />
        <path
          fill="transparent"
          opacity="1.000000"
          stroke="none"
          d="
          M157.162903,59.558167 
              C160.906357,55.706539 164.426483,52.170654 167.946609,48.634769 
              C167.430557,57.181343 162.434006,62.493137 155.582108,62.848743 
              C156.090759,61.734035 156.515167,60.803970 157.162903,59.558167 
          z"
        />
      </svg>
    );
  }
  